import * as bootstrap from "bootstrap";
import { AppPlugin } from "..";

export class BootstrapPlugin implements AppPlugin {
  public install() {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map((tooltipTriggerEl: HTMLElement) => {
      tooltipTriggerEl.removeAttribute('data-bs-toggle');
      return new bootstrap.Tooltip(tooltipTriggerEl)
    });
  }
}
