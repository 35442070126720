import { application } from './application';
import PasswordVisibility from 'stimulus-password-visibility';
import Clipboard from 'stimulus-clipboard';
import { LinkAccountController } from './LinkAccountController';
import { EmbeddedController } from './EmbeddedController';
import { PasswordStrengthController } from './PasswordStrengthController';
import { ToastController } from './ToastController';
import { AuthTypeSelectController } from './AuthTypeSelectController';

application.register('linkAccount', LinkAccountController);
application.register('embedded', EmbeddedController);
application.register('toast', ToastController);
application.register('password-strength', PasswordStrengthController);
application.register('password-visibility', PasswordVisibility);
application.register('auth-type-select', AuthTypeSelectController);
application.register('clipboard', Clipboard);
