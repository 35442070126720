import React, { useCallback, useEffect, useState } from 'react';
import * as bootstrap from 'bootstrap';
import { getCSRFParam, getCSRFToken } from '../../../utils/csrf';

interface PropTypes {
  triggerElement: HTMLElement;
  confirm?: string;
  commit?: string;
  cancel?: string;
  commitClass?: string;
  cancelClass?: string;
  verify?: string;
  verifyText?: string;
  verifyCaseSensitive?: string;
  title?: string;
  method?: string;
  params?: string;
}

function isBlank(str?: string): boolean {
  return (str === undefined || str === null || str === '');
}

export function ConfirmModal(props: PropTypes) {
  const [modal, setModal] = useState<bootstrap.Modal | undefined>(undefined);
  const [verifyInput, setVerifyInput] = useState<string>('');
  const [commitEnabled, setCommitEnabled] = useState<boolean>(isBlank(props.verify));
  const ref = React.createRef<HTMLDivElement>();
  const verifyCaseSensitive = props.verifyCaseSensitive === 'true';
  const formMethod = isBlank(props.method) || props.method === 'get' ? 'get' : 'post';
  const params = props.params && JSON.parse(props.params) || {};

  useEffect(() => {
    setModal(new bootstrap.Modal(ref.current));
  }, [ref.current]);

  useEffect(() => {
    if (isBlank(props.verify)) return;

    const verify = verifyCaseSensitive ? props.verify : props.verify.toLowerCase();
    const input = verifyCaseSensitive ? verifyInput : verifyInput.toLowerCase();

    setCommitEnabled(verify === input);
  });

  const toggle = useCallback(() => {
    modal && modal.toggle();
  }, [modal]);


  const submitForm = (ev) => {
    toggle();
  };

  props.triggerElement.addEventListener('click', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    toggle();
  });

  return (
    <div ref={ref} className="modal fade" tabIndex={-1}>
      <div className="modal-dialog">
        <div className="modal-content">
          <form method={formMethod} action={props.triggerElement.getAttribute('href')} onSubmit={submitForm} data-turbo="true">
            {formMethod !== 'get' && <>
              <input type="hidden" name="_method" value={props.method} />
              <input type="hidden" name={getCSRFParam()} value={getCSRFToken()} />
            </>}
            {Object.keys(params).map(key => (<input type="hidden" key={key} name={key} value={params[key]} />))}
            <div className="modal-header">
              <h3 className="modal-title">{props.title || 'Please confirm'}</h3>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>{props.confirm}</p>
              {!isBlank(props.verify) && <>
                <label className="form-label">{props.verifyText || `Please type "${props.verify}" below to proceed:`}</label>
                <input type="text" className="form-control" value={verifyInput} onChange={(e) => setVerifyInput(e.target.value)} />
              </>}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{props.cancel || 'Cancel'}</button>
              <input
                type="submit"
                className={`btn btn-${props.commitClass || 'danger'} ${commitEnabled ? '' : 'disabled'}`}
                value={props.commit || 'Confirm'}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
