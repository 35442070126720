import '@hotwired/turbo-rails';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap';
import 'chartkick/chart.js';
import LocalTime from 'local-time';
import HSSideNav from '../vendor/hs-navbar-vertical-aside';
import '../controllers';
import '../styles/main.scss';
import { PluginManager } from '../plugins';
import { ConfirmModalPlugin } from '../plugins/ConfirmModalPlugin';
import { ShowHidePasswordPlugin } from '../plugins/ShowHidePasswordPlugin';
import { BootstrapPlugin } from '../plugins/BootstrapPlugin';
import { AnalyticsPlugin } from '../plugins/AnalyticsPlugin';
import { SelectPlugin } from '../plugins/SelectPlugin';
import $ from 'jquery';

const analyticsPlugin = new AnalyticsPlugin(
  document.getElementById('analytics-data'),
);

const pluginManager = new PluginManager();
pluginManager.register(new BootstrapPlugin());
pluginManager.register(new ConfirmModalPlugin());
pluginManager.register(new ShowHidePasswordPlugin());
pluginManager.register(new SelectPlugin());
pluginManager.register(analyticsPlugin);

pluginManager.install();

let currNavbar;

const init = () => {
  LocalTime.start();

  const navbar = document.querySelector('.js-navbar-vertical-aside');
  if (currNavbar === navbar) return;

  currNavbar = navbar;
  new HSSideNav('.js-navbar-vertical-aside').init();

  (function () {
    // STYLE SWITCHER
    // =======================================================
    const $dropdownBtn = document.getElementById('selectThemeDropdown'); // Dropdowon trigger
    const $variants = document.querySelectorAll(
      `[aria-labelledby="selectThemeDropdown"] [data-icon]`,
    ); // All items of the dropdown

    // Function to set active style in the dorpdown menu and set icon for dropdown trigger
    const setActiveStyle = function () {
      $variants.forEach(($item) => {
        if (
          $item.getAttribute('data-value') ===
          HSThemeAppearance.getOriginalAppearance()
        ) {
          $dropdownBtn.innerHTML = `<i class="${$item.getAttribute(
            'data-icon',
          )}" />`;
          return $item.classList.add('active');
        }

        $item.classList.remove('active');
      });
    };

    // Add a click event to all items of the dropdown to set the style
    $variants.forEach(function ($item) {
      $item.addEventListener('click', function () {
        HSThemeAppearance.setAppearance($item.getAttribute('data-value'));
      });
    });

    // Call the setActiveStyle on load page
    setActiveStyle();

    // Add event listener on change style to call the setActiveStyle function
    window.addEventListener('on-hs-appearance-change', function () {
      setActiveStyle();
    });

    $('.select2').each(function () {
      const $elem = $(this);
      $elem.select2();
    });
  })();
};

document.addEventListener('readystatechange', init);
document.addEventListener('turbo:load', init);
document.addEventListener('turbo-frame:connected', init);

analyticsPlugin.identify();
