import { Controller } from "@hotwired/stimulus";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

export class ToastController extends Controller {
  durationValue: number
  hiddenValue: boolean

  static values = {
    duration: {
      type: Number,
      default: 3000,
    },
  }

  connect () {
    (this.element as HTMLElement).style.display = "none";
    this.show()
  }

  show () {
    Toastify({
      text: this.element.textContent?.trim() || "",
      duration: this.durationValue,
      close: true,
      gravity: "top",
      position: "right",
      stopOnFocus: true,
    }).showToast();
  }
}
