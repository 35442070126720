import React from 'react';
import ReactDOM from 'react-dom';
import { AppPlugin } from '..';
import { ConfirmModal } from './ConfirmModal';

export class ConfirmModalPlugin implements AppPlugin {
  public install(): void {
    // Turbo does not fire any event when part of the page is re-rendered.
    // This interval keeps checking if there are any changes on the page.
    // This is a temporary solution, follow the issue below where new events
    // are being added to Turbo:
    // https://github.com/hotwired/turbo/pull/167
    //
    // This solution may be refactored to create a single "ConfirmModal" for
    // every link. The ConfirmModal component can listen to any click and check
    // whether it's a confirm click, then adapt the form to the attributes of
    // the link. Look into stimulus as an option to fix this issue as well:
    // https://github.com/hotwired/turbo/issues/50
    setInterval(() => {
      document.querySelectorAll<HTMLElement>('[data-confirm]')
        .forEach((el) => this.installContainer(el));
    }, 500);
  }

  private installContainer(el: HTMLElement): void {
    const container = document.createElement('div');
    document.body.append(container);

    ReactDOM.render(<ConfirmModal triggerElement={el} { ...el.dataset } />, container);

    delete el.dataset.confirm;
  }
}
