import zxcvbn from 'zxcvbn';
import styles from './styles.module.scss';

const PASSWORD_LEVELS = [
  { text: 'Too weak', class: styles.barWeak },
  { text: 'Still weak, keep going!', class: styles.barWeak },
  { text: 'Good, almost there!', class: styles.barMedium },
  { text: 'Strong enough!', class: styles.barStrong },
  { text: 'Very strong, perfect!', class: styles.barStrong },
]

export class PasswordStrengthMeter {
  private readonly passwordInput: HTMLInputElement;
  private readonly strengthMeter: HTMLDivElement;
  private readonly strengthMeterBar: HTMLDivElement;
  private readonly strengthMeterText: HTMLSpanElement;

  constructor(passwordInput: HTMLInputElement, strengthMeter: HTMLDivElement) {
    this.strengthMeter = strengthMeter;
    this.passwordInput = passwordInput;
    this.strengthMeterBar = document.createElement('div');
    this.strengthMeterText = document.createElement('span');
    this.install();
  }

  private install() {
    const barWrapper = document.createElement('div');
    barWrapper.classList.add(styles.wrapper);

    this.strengthMeter.classList.add(styles.root);
    this.strengthMeterBar.classList.add(styles.bar);
    this.strengthMeterText.classList.add(styles.text);

    barWrapper.appendChild(this.strengthMeterBar);
    this.strengthMeter.appendChild(barWrapper);
    this.strengthMeter.appendChild(this.strengthMeterText);

    this.passwordInput.addEventListener('input', () => this.updateMeter());

    this.updateMeter();
  }

  private updateMeter() {
    this.strengthMeterBar.classList.remove(styles.barWeak);
    this.strengthMeterBar.classList.remove(styles.barMedium);
    this.strengthMeterBar.classList.remove(styles.barStrong);

    const password = this.passwordInput.value;
    if (password.length === 0) {
      this.strengthMeterBar.classList.add(styles.barWeak);
      this.strengthMeterBar.style.width = '0%';
      this.strengthMeterText.innerHTML = '&nbsp;';
      return;
    }

    const strength = this.calculateStrength(password);

    this.strengthMeterBar.style.width = ((strength.score + 1) * 20) + '%';

    const passwordLevel = PASSWORD_LEVELS[strength.score];
    this.strengthMeterBar.classList.add(passwordLevel.class);
    this.strengthMeterText.innerText = passwordLevel.text;
  }

  private calculateStrength(password: string) {
    return zxcvbn(password);
  }
}
