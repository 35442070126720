import { Controller } from '@hotwired/stimulus';

export class AuthTypeSelectController extends Controller {
  handleSelectChange = (queryParams, provider, formContainer) => {
    fetch(`/integrations/update_form?${queryParams}&id=${provider}`)
      .then((response) => response.text())
      .then((html) => {
        formContainer.innerHTML = html;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  connect() {
    const authenticationSelectElement = document.querySelector(
      '#authentication_config_key_field',
    );

    const environmentSelectElement =
      document?.querySelector('#environment_field');

    const formContainer = document.querySelector('div.content.container-fluid');
    const provider = document.querySelector('#provider').value;

    authenticationSelectElement?.addEventListener('change', () => {
      const selectedAuthValue = authenticationSelectElement.value;
      const authParam = 'selected_auth_config';

      const envSelectElement = document?.querySelector('#environment_field');

      const selectedEnvValue = envSelectElement?.value;
      const envParam = 'selected_env_config';

      const queryParams = selectedEnvValue
        ? `${authParam}=${selectedAuthValue}&${envParam}=${selectedEnvValue}`
        : `${authParam}=${selectedAuthValue}`;
      this.handleSelectChange(queryParams, provider, formContainer);
    });

    environmentSelectElement?.addEventListener('change', () => {
      const selectedEnvValue = environmentSelectElement.value;
      const envParam = 'selected_env_config';

      const authSelectElement = document.querySelector(
        '#authentication_config_key_field',
      );

      const selectedAuthValue = authSelectElement.value;
      const authParam = 'selected_auth_config';
      const queryParams = `${authParam}=${selectedAuthValue}&${envParam}=${selectedEnvValue}`;
      this.handleSelectChange(queryParams, provider, formContainer);
    });
  }
}
