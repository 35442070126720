export interface AppPlugin {
  install?: () => void;
  load?: () => void;
}

export class PluginManager {
  private plugins: AppPlugin[] = [];

  public register(plugin: AppPlugin): void {
    this.plugins.push(plugin);
  }

  public install(): void {
    const observer = new MutationObserver(() => this.installPlugins());
    observer.observe(document, {
      attributes: true,
      childList: true,
      subtree: true,
      attributeFilter: ["class", "data"],
    });
    this.installPlugins();
  }

  private installPlugins(): void {
    this.plugins.forEach((plugin) => plugin.install && plugin.install());
  }
}
