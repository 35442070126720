import posthog, { PostHog } from 'posthog-js';
import { AppPlugin } from '..';

export interface CurrentAnalyticsUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
}
export interface CurrentOrganization {
  id: number;
  name: string;
}

export class AnalyticsPlugin implements AppPlugin {
  public posthog: PostHog = posthog;
  private datasetElem: HTMLElement | null;

  constructor(datasetElem: HTMLElement | null) {
    this.datasetElem = datasetElem;
    if (datasetElem === null) {
      console.debug('Analytics dataset element not found');
    }
  }

  public install() {
    const posthogApiKey = this.datasetElem?.dataset?.posthogApiKey;
    if (typeof posthogApiKey === 'string') {
      // see https://posthog.com/docs/libraries/js#option-2-install-via-package-manager
      posthog.init(posthogApiKey, {
        api_host: 'https://eu.posthog.com',
        session_recording: {
          maskAllInputs: true,
          blockSelector: '.ph-no-capture',
        },
      });
      this.posthog = posthog;
    } else {
      console.debug(
        'Analytics Plugin: Posthog API key not found - Posthog client not initialized',
      );
    }
  }

  public identify() {
    const user: CurrentAnalyticsUser | null = JSON.parse(
      this.datasetElem?.dataset?.user ?? 'null',
    );
    const org: CurrentOrganization | null = JSON.parse(
      this.datasetElem?.dataset?.organization ?? 'null',
    );

    if (!user?.id) {
      console.debug(
        'Analytics Plugin: user ID not found - skipping identify call',
      );
      return;
    }
    const userData = {
      email: user?.email,
      email_domain: user?.email?.split('@')?.[1] ?? 'unknown_domain',
      first_name: user?.first_name,
      last_name: user?.last_name,
      role: user?.role,
      organization_id: org?.id?.toString(),
      organization_name: org?.name,
    };

    // refer to https://posthog.com/docs/product-analytics/identify
    this.posthog.identify(user.id.toString(), userData);
  }
}
