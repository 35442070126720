import { Controller } from '@hotwired/stimulus';
import { EventType } from '../libs/connect';

export class EmbeddedController extends Controller {
  static targets = [
    'connectInfo',
    'accountForm',
    'loading',
    'success',
    'error',
    'closeModal',
  ];

  SECRET_PLACEHOLDER = 'dummysecret:NvIc8guQjaLfaNWQUu18RfC72QC58J6eqh1xNbIIFIFeNx'

  initialize() {
    const linkedAccount = this.element.dataset.account;
    const error = this.element.dataset.error;
    const errorDescription = this.element.dataset.errorDescription;
    if (linkedAccount) return this.postAccount(JSON.parse(linkedAccount));
    if (error) return this.postFailed(error, errorDescription);

    this.processMessageCallback = this.processMessage.bind(this);
    window.addEventListener('message', this.processMessageCallback, false);

    this.closeModalTargets.forEach((el) => {
      el.addEventListener('click', (ev) => {
        if (ev.target === el) this.closeModal();
      });
    });
  }

  accountFormTargetConnected() {
    this.accountFormTarget.addEventListener('submit', () =>
      this.showScreen('loading'),
    );
  }

  disconnect() {
    window.removeEventListener('message', this.processMessageCallback, false);
  }

  processMessage(event) {
    if (event.origin !== location.origin) return;

    if (event.data.type === EventType.AccountConnected) {
      this.showScreen('success');
      parent.postMessage(event.data, '*');
    } else if (event.data.type === EventType.CloseOAuth2) {
      if(event.data.error) {
        this.showError(event.data.error, event.data.errorDescription);
      } else {
        this.showForm();
      }
    }
    this.connectWindow && this.connectWindow.close();
    this.connectWindow = undefined;
  }

  closeModal() {
    parent.postMessage({ type: EventType.CloseModal }, '*');
  }

  connectAccount(e) {
    const form = e.target.form;
    const strategy = form.querySelector('[name=strategy]').value;
    if (strategy === 'oauth2' || strategy === 'oidc') {
      e.preventDefault();

      if (!form.checkValidity()) {
        form.reportValidity();
        return;
      }
      
      return this.linkOauth2(e);
    }
  }

  updateProviderUrl(e) {
    const providerUrl = e.target.getAttribute('href');
    const form = e.target.form;
    const formFields = form.querySelectorAll('.form-control');

    if (!formFields || !formFields.length) {
      return providerUrl;
    }

    const formFieldsValues = [...formFields].filter((field) => field.value !== this.SECRET_PLACEHOLDER)
      .map((field) => ({
        fieldName: field.getAttribute('data-key'),
        fieldValue: field.value,
      }));

    const encodedAccountCredentials = formFieldsValues.map((field) => {
      return `${field.fieldName}=${field.fieldValue}`;
    });

    return `${providerUrl}&${encodedAccountCredentials.join('&')}`;
  }

  linkOauth2(e) {
    const width = '1024';
    const height = '800';
    const screenX =
      typeof window.screenX != 'undefined' ? window.screenX : window.screenLeft;
    const screenY =
      typeof window.screenY != 'undefined' ? window.screenY : window.screenTop;
    const outerWidth =
      typeof window.outerWidth != 'undefined'
        ? window.outerWidth
        : document.body.clientWidth;
    const outerHeight =
      typeof window.outerHeight != 'undefined'
        ? window.outerHeight
        : document.body.clientHeight - 22;
    const left = parseInt(screenX + (outerWidth - width) / 2, 10);
    const top = parseInt(screenY + (outerHeight - height) / 2.5, 10);
    const features =
      'width=' + width + ',height=' + height + ',left=' + left + ',top=' + top;

    const providerUrl = this.updateProviderUrl(e);

    this.connectWindow = window.open(providerUrl, 'Connect Account', features);

    this.showScreen('loading');

    if (window.focus) this.connectWindow.focus();

    this.checkWindowState();
  }

  showForm() {
    this.showScreen('connect');
  }

  showError(error, errorDescription) {
    const errorEl = this.errorTarget.querySelector('.connection-error');
    const errorDescEl = this.errorTarget.querySelector('.connection-error-description');
    errorEl.innerText = error;
    if(errorDescription) {
      errorDescEl.innerText = errorDescription;
    } else {
      errorDescEl.innerHTML = '<i>No description</i>';
    }
    this.showScreen('error');
  }

  checkWindowState() {
    if (this.connectWindow && this.connectWindow.closed) {
      this.showScreen('connect');
    } else {
      setTimeout(this.checkWindowState.bind(this), 1000);
    }
  }

  showScreen(screen) {
    this.loadingTarget.style.display = 'none';
    this.connectInfoTarget.style.display = 'none';
    this.successTarget.style.display = 'none';
    this.errorTarget.style.display = 'none';

    if (screen === 'loading') this.loadingTarget.style.display = null;
    if (screen === 'connect') this.connectInfoTarget.style.display = null;
    if (screen === 'success') this.successTarget.style.display = 'flex';
    if (screen === 'error') this.errorTarget.style.display = 'flex';
  }

  postAccount(account) {
    const target = window.opener || window;
    target.postMessage({ type: EventType.AccountConnected, account }, '*');
  }

  postFailed(error, errorDescription) {
    const target = window.opener || window;

    target.postMessage({ type: EventType.CloseOAuth2, error: error, errorDescription: errorDescription }, '*');
  }
}
