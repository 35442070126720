import { Controller } from '@hotwired/stimulus';
import { PasswordStrengthMeter } from './PasswordStrengthMeter';

export class PasswordStrengthController extends Controller {
  static targets = ['input', 'strengthMeter'];

  connect() {
    new PasswordStrengthMeter(this.inputTarget, this.strengthMeterTarget);
  }
}
