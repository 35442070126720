import { AppPlugin } from "..";

export class ShowHidePasswordPlugin implements AppPlugin {
  public install() {
    this.installElements(true);
    this.installElements(false);
    this.installElements(null);
  }

  private installElements(show: boolean) {
    const selector = this.getSelector(show);
    document.querySelectorAll<HTMLElement>(`[${selector}]`)
      .forEach((el) => {
        const targetSelector = el.getAttribute(selector);
        this.installElement(el, targetSelector, show);
        el.removeAttribute(selector);
        el.setAttribute(`${selector}-installed`, targetSelector);
      });
  }

  private installElement(el: HTMLElement, targetSelector: string, show: boolean): void {
    if (show !== null) {
      const target = document.querySelector<HTMLInputElement>(targetSelector);
      if ((target.type === 'password' && !show) || (target.type === 'text' && show)) {
        el.classList.add('d-none');
      }
    }
    this.setupListener(el, targetSelector, show);
  }

  private setupListener(el: HTMLElement, targetSelector: string, show: boolean): void {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      const target = document.querySelector<HTMLInputElement>(targetSelector);
      const status = this.toggleInput(target, show);
      const statusSelector = this.getSelector(status);
      const oppositeSelector = this.getSelector(!status);

      document.querySelectorAll<HTMLElement>(`[${statusSelector}-installed]`)
        .forEach((el) => el.classList.add('d-none'));
      document.querySelectorAll<HTMLElement>(`[${oppositeSelector}-installed]`)
        .forEach((el) => el.classList.remove('d-none'));
    });
  }

  private getSelector(show: boolean) {
    if (show === null) return 'data-show-hide-password'
    if (show) return 'data-show-password';
    return 'data-hide-password';
  }

  private toggleInput(input: HTMLInputElement, show: boolean) {
    if (show === null) {
      input.type = input.type === 'password' ? 'text' : 'password';
      return input.type === 'text';
    }
    input.type = show ? 'text' : 'password';
    return show;
  }
}
