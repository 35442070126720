import { Controller } from '@hotwired/stimulus';
import { Connect } from '../libs/connect';

export class LinkAccountController extends Controller {
  connect() {
    const linkedAccount = this.element.dataset.account;
    if (linkedAccount) this.postAccount(JSON.parse(linkedAccount), safeUrl);
  }

  async startLink(e) {
    try {
      const projectId = e.target.closest('[data-project-id]').dataset.projectId;

      const connectSessionToken = await this.createConnectSession(projectId);

      Connect.start({
        apiUrl: location.origin,
        sessionToken: connectSessionToken,
        onSuccess: () => setTimeout(() => location.reload(), 1500),
      });
    } catch (e) {
      console.warn(`startLink(): ${e.message}`);
      location.href = '/accounts';
    }
  }

  async createConnectSession(projectId) {
    const result = await fetch('/api/v1/connect_sessions', {
      method: 'post',
      credentials: 'same-origin',
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Project-Id': projectId,
      },
      body: JSON.stringify({
        origin_owner_id: 'sample_account',
        origin_owner_name: 'Sample Organization',
      }),
    });

    if (result.status !== 200) {
      throw new Error(`[${result.status}] Failed to create connect session.`);
    }

    const data = await result.json();
    return data.token;
  }

  async startEdit(e) {
    try {
      const projectId = e.target.closest("[data-project-id]").dataset.projectId;
      const accountId = e.target.closest("[data-account-id]").dataset.accountId;
      const provider = e.target.closest("[data-provider]").dataset.provider;
      const originOwnerId = e.target.closest("[data-origin-owner-id]").dataset.originOwnerId;
      const originOwnerName = e.target.closest("[data-origin-owner-name]").dataset.originOwnerName;

      const connectSessionToken = await this.createConnectSessionForAccount(projectId, accountId, provider, originOwnerId, originOwnerName);
      Connect.start({
        apiUrl: location.origin,
        sessionToken: connectSessionToken,
        onSuccess: () => setTimeout(() => location.reload(), 1500),
      });
    } catch (e) {
        console.warn(`startEdit(): ${e.message}`);
        location.href = '/accounts';
    }
  }

  async createConnectSessionForAccount(projectId, accountId, provider, originOwnerId, originOwnerName) {

    const result = await fetch("/api/v1/connect_sessions", {
      method: "post",
      credentials: "same-origin",
      cache: "no-cache",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-Project-Id": projectId,
      },
      body: JSON.stringify({
        origin_owner_id: originOwnerId,
        origin_owner_name: originOwnerName,
        account_secure_id: accountId,
        provider: provider,
      }),
    });

    if (result.status !== 200) {
      throw new Error(`[${result.status}] Failed to create connect session.`);
    } 

    const data = await result.json();
    return data.token;
  }
}
